<template>
  <v-row>
    <v-col cols="12" sm="6" md="8">
      <v-radio-group
        v-model="currentFormLanguage"
        @change="$emit('changeFormLanguage', currentFormLanguage)"
        class="mt-1"
        row
        hide-details
      >
        <v-radio
          v-for="(language, index) in filteredLanguages"
          :key="index"
          :label="language.nativeName"
          :value="language.code"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import GetLanguagesFromISO from '@/mixins/getLanguagesFromISO-639-1'

export default {
  name: 'GetFormLanguage',

  mixins: [GetLanguagesFromISO],

  data: () => ({
    currentFormLanguage: 'uk',
  }),

  mounted() {
    this.getAllLanguages()
    this.getAllAvailableFilteredLanguages()
  },
}
</script>
