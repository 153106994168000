import ISO6391 from 'iso-639-1'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    languages: [],
    filteredLanguages: [],
  }),

  computed: {
    ...mapGetters('pushNotification', ['getAvailableNotificationLanguages']),
  },

  methods: {
    getAllLanguages() {
      this.languages = ISO6391.getAllCodes().map((code) => ({
        code,
        name: ISO6391.getName(code),
        nativeName: ISO6391.getNativeName(code),
      }))
    },
    getAllAvailableFilteredLanguages() {
      this.filteredLanguages = this.languages.filter((language) => this.getAvailableNotificationLanguages.includes(language.code))
    },
  },
}
